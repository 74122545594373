import React from 'react';

const VideoPlay = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="140" height="140" viewBox="0 0 209 209">
    <g transform="translate(-856 -2658)">
      <g transform="translate(856 2658)" fill="none" stroke="currentColor" strokeWidth="12">
        <circle cx="104.5" cy="104.5" r="104.5" stroke="none" />
        <circle cx="104.5" cy="104.5" r="98.5" fill="none" />
      </g>
      <path
        d="M48.37,21.053a14,14,0,0,1,24.261,0l36.276,62.958A14,14,0,0,1,96.776,105H24.224a14,14,0,0,1-12.13-20.989Z"
        transform="translate(1032 2702) rotate(90)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default VideoPlay;
